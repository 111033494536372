import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';

const data = [
  {
    link: 'https://github.com/abdullah50053',
    label: 'Github',
    icon: faGithub,
  },
  {
    link: 'https://www.linkedin.com/in/abdullah-hasani/',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  {
    link: 'mailto:hello@abdullahhasani.com',
    label: 'Email',
    icon: faEnvelope,
  },
  {
    link: 'https://instagram.com/abdullahhasani.jpg',
    label: 'Instagram',
    icon: faInstagram,
  },
];

export default data;
